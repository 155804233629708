import { XHRRequestCanceler } from '../../../../../Libs/xhr/XHRRequestCanceler';
import { IUserDataService, UserDataServiceName } from '../../../../DataServices/UserDataService';
import { BackOfficeUserRole, MeResponse } from '../../../../Models/Users/Users';
import { IoC } from '../../../ServicesContainer';
import { BaseReduxService } from '../../Api/BaseReduxService';
import {
    IOrganizationService,
    OrganizationsServiceName,
} from '../../Organizations/OrganizationsService';
import { MeActions } from './MeActions';

//import { createAbility } from './permissions/createAbility';
//import { getMeRules } from './permissions/getMeRules';

export interface IMeService {
    getMyInfo: (cts?: XHRRequestCanceler) => Promise<MeResponse | any>;
    changeOrganization: (organization_id: string) => void;
}

class MeService extends BaseReduxService implements IMeService {
    private userDataService: IUserDataService;
    private organizationService: IOrganizationService;
    constructor() {
        super();
        this.organizationService = IoC.get<IOrganizationService>(OrganizationsServiceName);
        this.userDataService = IoC.get<IUserDataService>(UserDataServiceName);
    }

    public updateAbilityPermissions = (extraRules?: any, updaterName?: any) => (
        dispatch: any,
        getState: any
    ) => {
        // const {
        //     Me: { ability: { rules = [] } = { rules: [] } },
        // } = getState();
        // const nextAbility = createAbility([...rules, ...extraRules]);
        // dispatch(MeActions.setAbility(nextAbility, updaterName));
    };

    public changeOrganization = (organization_id: string) => {
        const info = this.getState().Me.data;
        if (info) {
            this.dispatch(MeActions.setInfo({ ...info, organization_id }));
        }
    };

    public getMyInfo = async (cts?: XHRRequestCanceler): Promise<MeResponse | any> => {
        this.dispatch(MeActions.setInitialized(false));

        try {
            const response: MeResponse = await this.userDataService.getMyInfo(cts);

            this.dispatch(MeActions.setInfo(response.data));
            // dispatch(MeActions.setAbility(createAbility(getMeRules(data)), 'init_me'));
            this.dispatch(MeActions.setInitialized(true));

            if (
                !this.getState().Me.data?.organization_id &&
                response.data.role === BackOfficeUserRole.SuperAdmin &&
                !response.data.organization_id
            ) {
                this.organizationService
                    .getOrganizations(new XHRRequestCanceler(), 0, 10)
                    .then((response) => this.changeOrganization(response.data[0].id));
            }

            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(MeActions.setInfo());
            //dispatch(MeActions.setAbility(createAbility(getMeRules()), 'init_no_me'));
            this.dispatch(MeActions.setInitialized(true));
            return Promise.reject(error);
        }
    };
}

const MeServiceName = 'MeService';
export { MeService, MeServiceName };
