import { BaseReduxService } from "../Api/BaseReduxService";
import { Messages } from "./typings/LocalMessageActionTypes";

export interface II18nService {
    getString: (id: string) => string;
    getMessages: () => Messages;
}

class I18nService extends BaseReduxService implements II18nService {

    public getString = (id: string): string => {
        const messages = this.getMessages();
        return messages[id];
    }

    public getMessages = (): Messages => {
        const messages: { [key: string]: string } = this.getState().I18n.messages;
        return messages
    }
}

const I18nServiceName = 'I18nServiceName';
export { I18nService, I18nServiceName }