import moment from 'moment';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import {
    OrganizationDetailsResult,
    OrganizationResult,
    OrganizationState,
    StatisticsResult,
} from '../Models/Organizations/Organizations';
import { BaseDataService } from './Base/BaseDataService';

const OrganizationEndpoints = {
    LIST_ORGANIZATIONS: '/api-admin/v1/organizations', // GET
    CREATE_ORGANIZATION: '/api-admin/v1/organizations', // POST
    UPDATE_ORGANIZATION: (id: string) => `/api-admin/v1/organizations/${id}`, // PUT
    DETAIL_ORGANIZATION: (id: string) => `/api-admin/v1/organizations/${id}`, // GET
    EXPORT_ORGANIZATIONS: '/api-admin/v1/organizations/export', // GET
    GET_STATISTICS: '/api-admin/v1/statistics', // GET
};

export interface IOrganizationDataService {
    getOrganizations: (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number
    ) => Promise<OrganizationResult>;

    getOrganizationDetails: (
        cts: XHRRequestCanceler,
        id: string
    ) => Promise<OrganizationDetailsResult>;

    createOrganization: (
        cts: XHRRequestCanceler,
        name: string,
        contact_first_name: string,
        contact_last_name: string,
        contact_email: string
    ) => Promise<OrganizationDetailsResult>;

    updateOrganization: (
        id: string,
        cts: XHRRequestCanceler,
        company_name: string,
        kbis: string,
        tva: string,
        address: string,
        organization_phone_number: string,
        state: OrganizationState,
        contact_first_name: string,
        contact_last_name: string,
        contact_phone_number: string,
        contact_email: string,
        show_calendar_hour: boolean
    ) => Promise<OrganizationDetailsResult>;

    getStatistics: (
        cts: XHRRequestCanceler,
        start_date: Date,
        end_date: Date,
        organization_id?: string
    ) => Promise<StatisticsResult>;
}

class OrganizationDataService extends BaseDataService implements IOrganizationDataService {
    getOrganizations = (
        cts: XHRRequestCanceler,
        page?: number,
        count?: number
    ): Promise<OrganizationResult> => {
        const params = {
            page: page,
            count: count,
        };
        return this.get<OrganizationResult>(OrganizationEndpoints.LIST_ORGANIZATIONS, cts, {
            params,
        });
    };

    getOrganizationDetails = (cts: XHRRequestCanceler, id: string) =>
        this.get<OrganizationDetailsResult>(OrganizationEndpoints.DETAIL_ORGANIZATION(id), cts);

    createOrganization = (
        cts: XHRRequestCanceler,
        name: string,
        admin_first_name: string,
        admin_last_name: string,
        admin_email: string
    ): Promise<OrganizationDetailsResult> => {
        const body = {
            name,
            admin_first_name,
            admin_last_name,
            admin_email,
        };
        return this.post<OrganizationDetailsResult>(
            OrganizationEndpoints.CREATE_ORGANIZATION,
            body,
            cts
        );
    };

    updateOrganization = (
        id: string,
        cts: XHRRequestCanceler,
        company_name: string,
        kbis: string,
        tva: string,
        address: string,
        organization_phone_number: string,
        state: OrganizationState,
        contact_first_name: string,
        contact_last_name: string,
        contact_phone_number: string,
        contact_email: string,
        show_calendar_hour: boolean
    ): Promise<OrganizationDetailsResult> => {
        const body = {
            company_name,
            kbis,
            tva,
            address,
            organization_phone_number,
            state,
            contact_first_name,
            contact_last_name,
            contact_phone_number,
            contact_email,
            show_calendar_hour,
        };
        return this.put<OrganizationDetailsResult>(
            OrganizationEndpoints.UPDATE_ORGANIZATION(id),
            body,
            cts
        );
    };

    getStatistics = (
        cts: XHRRequestCanceler,
        start_date: Date,
        end_date: Date,
        organization_id?: string
    ): Promise<StatisticsResult> => {
        const params = {
            startDate: moment(start_date).toISOString(true),
            endDate: moment(end_date).toISOString(true),
            organizationId: organization_id,
        };
        return this.get<StatisticsResult>(OrganizationEndpoints.GET_STATISTICS, cts, { params });
    };
}

const OrganizationDataServiceName = 'OrganizationDataService';
export { OrganizationDataService, OrganizationDataServiceName };
