import { ErrorHandlerName, IErrorHandler } from '../Libs/xhr/ErrorHandler';
import { Constants } from './Constants';
import {
    AuthDataService,
    AuthDataServiceName,
    IAuthDataService,
} from './DataServices/AuthenticationDataService';
import {
    IMediaDataService,
    MediaDataService,
    MediaDataServiceName,
} from './DataServices/MediaDataService';
import {
    IOrganizationDataService,
    OrganizationDataService,
    OrganizationDataServiceName,
} from './DataServices/OrganizationDataService';
import {
    IPasswordDataService,
    PasswordDataService,
    PasswordDataServiceName,
} from './DataServices/PasswordDataService';
import {
    IPrestationsDataService,
    PrestationsDataService,
    PrestationsDataServiceName,
} from './DataServices/PrestationDataService';
import {
    ISitesDataService,
    SitesDataService,
    SitesDataServiceName,
} from './DataServices/SitesDataService';
import {
    IUserDataService,
    UserDataService,
    UserDataServiceName,
} from './DataServices/UserDataService';
import {
    IWorkersDataService,
    WorkersDataService,
    WorkersDataServiceName,
} from './DataServices/WorkersDataService';
import { RudantErrorHandler } from './Errors/RudantErrorHandler';
import { initializeApiClient } from './Services/Data/Api/ApiService';
import { AuthService, AuthServiceName } from './Services/Data/Authentication/AuthenticationService';
import { I18nService, I18nServiceName, II18nService } from './Services/Data/I18n/I18nService';
import {
    IOrganizationService,
    OrganizationsService,
    OrganizationsServiceName,
} from './Services/Data/Organizations/OrganizationsService';
import {
    IPrestationsService,
    PrestationsService,
    PrestationsServiceName,
} from './Services/Data/Prestations/PrestationsService';
import { ISitesService, SitesService, SitesServiceName } from './Services/Data/Sites/SitesService';
import {
    BackOfficeUsersService,
    BackOfficeUsersServiceName,
    IBackOfficeUsersService,
} from './Services/Data/Users/BackOfficeUsers/BackOfficeUsersService';
import { MeService, MeServiceName } from './Services/Data/Users/Me/MeService';
import {
    IWorkersService,
    WorkersService,
    WorkersServiceName,
} from './Services/Data/Workers/WorkersService';
import { ILogService, LogServiceName, RudantLogService } from './Services/RudantLogService';
import { IoC } from './Services/ServicesContainer';

export class CoreBootstrapper {
    public initializeCore = (): void => {
        const appErrorHandler = IoC.get<IErrorHandler>(ErrorHandlerName);

        //* Setup Api client
        initializeApiClient(Constants.getRoot(), appErrorHandler);
    };

    protected initializeCoreServices = (): void => {
        //*Register * Log service
        IoC.bind<ILogService>(LogServiceName).toConstantValue(new RudantLogService());
        IoC.bind<II18nService>(I18nServiceName).toConstantValue(new I18nService());

        //* Create app error handler
        IoC.bind<IErrorHandler>(ErrorHandlerName).toConstantValue(new RudantErrorHandler());

        //* Register data services
        IoC.bind<IAuthDataService>(AuthDataServiceName).toConstantValue(new AuthDataService());
        IoC.bind<IMediaDataService>(MediaDataServiceName).toConstantValue(new MediaDataService());
        IoC.bind<IUserDataService>(UserDataServiceName).toConstantValue(new UserDataService());
        IoC.bind<IPasswordDataService>(PasswordDataServiceName).toConstantValue(
            new PasswordDataService()
        );
        IoC.bind<ISitesDataService>(SitesDataServiceName).toConstantValue(new SitesDataService());
        IoC.bind<IWorkersDataService>(WorkersDataServiceName).toConstantValue(
            new WorkersDataService()
        );
        IoC.bind<IPrestationsDataService>(PrestationsDataServiceName).toConstantValue(
            new PrestationsDataService()
        );

        //* Register Redux services
        IoC.bind<AuthService>(AuthServiceName).toConstantValue(new AuthService());
        IoC.bind<IBackOfficeUsersService>(BackOfficeUsersServiceName).toConstantValue(
            new BackOfficeUsersService()
        );
        IoC.bind<ISitesService>(SitesServiceName).toConstantValue(new SitesService());
        IoC.bind<IWorkersService>(WorkersServiceName).toConstantValue(new WorkersService());
        IoC.bind<IPrestationsService>(PrestationsServiceName).toConstantValue(
            new PrestationsService()
        );

        IoC.bind<IOrganizationDataService>(OrganizationDataServiceName).toConstantValue(
            new OrganizationDataService()
        );
        IoC.bind<IOrganizationService>(OrganizationsServiceName).toConstantValue(
            new OrganizationsService()
        );
        IoC.bind<MeService>(MeServiceName).toConstantValue(new MeService());
    };
}
