import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { getAuthRoutes } from './Routes/AuthRoutes';
import { getPrestationRoutes } from './Routes/PrestationRoutes';
import { getSiteRoutes } from './Routes/SiteRoutes';
import { getUserRoutes } from './Routes/UserRoutes';
import { getUtilsRoutes } from './Routes/UtilsRoutes';
import { getWorkerRoutes } from './Routes/WorkerRoutes';
import { AppRoutes } from '../../Core/Routes/Routes';

const HOME_ROUTE = AppRoutes.WORKER_LIST;
const DEFAULT_ROUTE = HOME_ROUTE;

const getAppRoutes = (): AppRouteType[] => [
    ...getAuthRoutes({}),
    ...getWorkerRoutes({}),
    ...getPrestationRoutes({}),
    ...getSiteRoutes({}),
    ...getUserRoutes({}),
    ...getUtilsRoutes({}),
];

export { getAppRoutes, DEFAULT_ROUTE, HOME_ROUTE };
