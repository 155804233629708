import { history } from '../../Configs/history/history';
import { INavigationService } from '../../Core/Services/Contracts/INavigationService';
import { AppRoutes } from '../../Core/Routes/Routes';

export interface NavigationServiceState {
    routeList: Array<string>;
}

//* Generic detail route
const getDetailsRoute = (id: string, detailsRoute: string): string =>
    `${detailsRoute}`.replace(':id', id);

export class NavigationService implements INavigationService {
    private state: { routeList: Array<string> };

    constructor() {
        this.state = { routeList: [] };
        history.listen(this.listen);
    }

    goTo = (route: string) => history.push(route);
    goToHome = () => history.push('/');

    //* AUTHENTICATION
    goToLogin = (): void => history.push(AppRoutes.LOGIN);
    goToForgotPassword = (): void => history.push(AppRoutes.PASSWORD_FORGOT);

    //* WORKERS
    goToWorkerList = (): void => history.push(AppRoutes.WORKER_LIST);
    goToWorkerCreate = (): void => history.push(AppRoutes.WORKER_CREATE);
    goToWorkerDetails = (id: string): void =>
        history.push(getDetailsRoute(id, AppRoutes.WORKER_DETAILS));
    getWorkerDetails = (id: string): string => getDetailsRoute(id, AppRoutes.WORKER_DETAILS);

    //* PRESTATIONS
    goToPrestationList = (): void => history.push(AppRoutes.PRESTATION_LIST);
    goToPrestationCreate = (): void => history.push(AppRoutes.PRESTATION_CREATE);
    goToPrestationDetails = (id: string): void =>
        history.push(getDetailsRoute(id, AppRoutes.PRESTATION_DETAILS));
    getPrestationDetails = (id: string): string =>
        getDetailsRoute(id, AppRoutes.PRESTATION_DETAILS);

    //* SITES
    goToSiteList = (): void => history.push(AppRoutes.SITE_LIST);
    goToSiteCreate = (): void => history.push(AppRoutes.SITE_CREATE);
    goToSiteDetails = (id: string): void =>
        history.push(getDetailsRoute(id, AppRoutes.SITE_DETAILS));
    getSiteDetails = (id: string): string => getDetailsRoute(id, AppRoutes.SITE_DETAILS);

    //* USERS
    goToUserList = (): void => history.push(AppRoutes.USER_LIST);
    goToUserCreate = (): void => history.push(AppRoutes.USER_CREATE);
    goToUserDetails = (id: string): void =>
        history.push(getDetailsRoute(id, AppRoutes.USER_DETAILS));
    getUserDetails = (id: string): string => getDetailsRoute(id, AppRoutes.USER_DETAILS);

    //* Utils
    goToCgu = () => history.push(AppRoutes.CGU);
    goToInfo = (): Window | null => window.open('https://ticktime.fr', '_blank');

    getPreviousRoute = (): string => {
        if (this.state.routeList.length >= 2) {
            return this.state.routeList[this.state.routeList.length - 2];
        } else {
            return '';
        }
    };

    listen = (action: any) => {
        this.state.routeList.push(action.pathname);
    };
}
