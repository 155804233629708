import { createMuiTheme } from '@material-ui/core';
import orange from '@material-ui/core/colors/deepOrange';
import MuiThemeProviderOrigin from '@material-ui/core/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import React from 'react';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#3fc482',
        },
        secondary: orange,
        type: 'light',
    },
});

const MuiThemeProvider = ({ children }) => {
    return <MuiThemeProviderOrigin theme={theme}>{children}</MuiThemeProviderOrigin>;
};

MuiThemeProvider.propTypes = {
    children: PropTypes.node,
};

MuiThemeProvider.defaultProps = {
    children: null,
};

export { MuiThemeProvider };
