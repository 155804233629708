import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { PrestationDetailsResponse, PrestationsResponse } from '../models/Prestations/Prestations';
import { BaseDataService } from './Base/BaseDataService';

const PrestationsEndpoints = {
    getPrestations: '/api-admin/v1/services',
    createPrestation: '/api-admin/v1/services',
    getPrestationDetails: (prestationId: string) => `/api-admin/v1/services/${prestationId}`,
    deletePrestation: (prestationId: string) => `/api-admin/v1/services/${prestationId}`,
    updatePrestation: (prestationId: string) => `/api-admin/v1/services/${prestationId}`,
    exportXLS: '/api-admin/v1/services/export',
};

export interface IPrestationsDataService {
    getPrestations: (
        cts: XHRRequestCanceler,
        startDate: Date,
        endDate: Date,
        search: string,
        anomaly: boolean | null,
        page: number,
        count: number,
        siteId: string | undefined,
        workerId: string | undefined,
        organization_id?: string
    ) => Promise<PrestationsResponse>;

    createPrestation: (
        cts: XHRRequestCanceler,
        siteId: string,
        workerId: string,
        startDate: string,
        endDate: string,
        organization_id?: string
    ) => Promise<PrestationDetailsResponse>;

    getPrestationDetails: (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => Promise<PrestationDetailsResponse>;

    deletePrestation: (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => Promise<{}>;
    updatePrestation: (
        cts: XHRRequestCanceler,
        prestationId: string,
        siteId: string,
        workerId: string,
        startDate: string,
        startEditedBy: string,
        endDate: string,
        endEditedBy: string,
        organization_id?: string
    ) => Promise<PrestationDetailsResponse>;

    downloadPhoto: (photoUrl: string) => Promise<Response>;
}

class PrestationsDataService extends BaseDataService implements IPrestationsDataService {
    public getPrestations = (
        cts: XHRRequestCanceler,
        startDate: Date,
        endDate: Date,
        search: string,
        anomaly: boolean | null,
        page: number,
        count: number,
        siteId: string | undefined,
        workerId: string | undefined,
        organization_id?: string
    ): Promise<PrestationsResponse> => {
        const params = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            search: search,
            withAnomaly: anomaly,
            page: page,
            count: count,
            siteId: siteId,
            teamUserId: workerId,
            organizationId: organization_id,
        };
        return this.get<PrestationsResponse>(PrestationsEndpoints.getPrestations, cts, {
            params,
        });
    };

    public createPrestation = (
        cts: XHRRequestCanceler,
        siteId: string,
        workerId: string,
        startDate: string,
        endDate: string,
        organization_id?: string
    ): Promise<PrestationDetailsResponse> => {
        const body = {
            site_id: siteId,
            team_user_id: workerId,
            start_date: startDate,
            end_date: endDate,
            organization_id: organization_id,
        };
        return this.post(PrestationsEndpoints.createPrestation, body, cts);
    };

    public getPrestationDetails = (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<PrestationDetailsResponse>(
            PrestationsEndpoints.getPrestationDetails(prestationId),
            cts,
            { params }
        );
    };
    public deletePrestation = (
        cts: XHRRequestCanceler,
        prestationId: string,
        organization_id?: string
    ) => {
        const params = {
            organizationId: organization_id,
        };
        return this.delete(PrestationsEndpoints.deletePrestation(prestationId), cts, { params });
    };

    public updatePrestation = (
        cts: XHRRequestCanceler,
        prestationId: string,
        siteId: string,
        workerId: string,
        startDate: string,
        startEditedBy: string,
        endDate: string,
        endEditedBy: string,
        organization_id?: string
    ): Promise<PrestationDetailsResponse> => {
        const body = {
            site_id: siteId,
            team_user_id: workerId,
            start_date: startDate,
            clocking_in_by_id: startEditedBy,
            end_date: endDate,
            clocking_off_by_id: endEditedBy,
            organization_id: organization_id,
        };
        return this.put(PrestationsEndpoints.updatePrestation(prestationId), body, cts);
    };

    public downloadPhoto = (photoUrl: string) => {
        return fetch(photoUrl);
    };
}

const PrestationsDataServiceName = 'PrestationsDataService';

export { PrestationsDataService, PrestationsDataServiceName };
