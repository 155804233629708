import { Site, SiteState } from '../../../../Models/Sites/Sites';

export enum SitesActionsTypes {
    SET_SITES_LIST = 'app/sites/set/sites',
    SET_SITES_STATE = 'app/sites/set/state',
    SET_SITES_SEARCH = 'app/sites/set/search',
    SET_SITES_PAGE = 'app/sites/set/page',
    SET_SITES_INITIALIZED = 'app/sites/set/initialized',
    SET_CLIENTS = 'app/sites/set/clients',
}

export interface SitesActionSetSitesList {
    type: SitesActionsTypes.SET_SITES_LIST;
    payload: { sites: Array<Site> };
}

export interface SitesActionSetSitesState {
    type: SitesActionsTypes.SET_SITES_STATE;
    payload: { state: SiteState };
}

export interface SitesActionSetSitesSearch {
    type: SitesActionsTypes.SET_SITES_SEARCH;
    payload: { search: string };
}

export interface SitesActionSetSitesPage {
    type: SitesActionsTypes.SET_SITES_PAGE;
    payload: { page: number };
}

export interface SitesActionSetSitesInitialized {
    type: SitesActionsTypes.SET_SITES_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SitesActionSetClients {
    type: SitesActionsTypes.SET_CLIENTS;
    payload: { clients: Array<string> };
}

export type SitesActions =
    | SitesActionSetSitesList
    | SitesActionSetSitesState
    | SitesActionSetSitesSearch
    | SitesActionSetSitesPage
    | SitesActionSetSitesInitialized
    | SitesActionSetClients;
