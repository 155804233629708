import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../../../Constants';
import { ISitesDataService, SitesDataServiceName } from '../../../DataServices/SitesDataService';
import {
    ClientsResponse,
    SiteDetailsResponse,
    SitesResponse,
    SiteState,
} from '../../../models/Sites/Sites';
import { ExportUtils } from '../../../Utils/ExportUtils';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { SitesActions } from './SitesActions';

export interface ISitesService {
    getSites: (
        cts: XHRRequestCanceler,
        siteState?: SiteState | string,
        search?: string,
        count?: number,
        page?: number,
        organization_id?: string
    ) => Promise<SitesResponse>;

    getSiteDetails: (
        cts: XHRRequestCanceler,
        id: string,
        organization_id?: string
    ) => Promise<SiteDetailsResponse>;

    createSite: (
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        organization_id?: string
    ) => Promise<SiteDetailsResponse>;

    updateSite: (
        id: string,
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        organization_id?: string
    ) => Promise<SiteDetailsResponse>;

    getClients: (cts: XHRRequestCanceler, organization_id?: string) => Promise<ClientsResponse>;

    changePage: (page: number) => void;
    changeState: (state: string) => void;
    changeSearch: (search: string) => void;
    exportXLS: (auth: string, organization_id?: string) => void;
}

class SitesService extends BaseReduxService implements ISitesService {
    private readonly sitesDataService: ISitesDataService;

    constructor() {
        super();
        this.sitesDataService = IoC.get<ISitesDataService>(SitesDataServiceName);
    }

    public getSites = async (
        cts: XHRRequestCanceler,
        siteState?: SiteState | string,
        search?: string,
        count?: number,
        page?: number,
        organization_id?: string
    ): Promise<SitesResponse> => {
        try {
            const response: SitesResponse = await this.sitesDataService.getSites(
                cts,
                page,
                count,
                search,
                siteState,
                organization_id
            );
            this.dispatch(SitesActions.SetSitesList(response.data));
            this.dispatch(SitesActions.SetSitesInitialized(true));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public changePage = (page: number): void => {
        this.dispatch(SitesActions.SetSitesPage(page));
    };

    public changeState = (state: string): void => {
        this.dispatch(SitesActions.SetSitesState(state));
    };

    public changeSearch = (search: string): void => {
        this.dispatch(SitesActions.SetSitesSearch(search));
    };

    public getSiteDetails = async (
        cts: XHRRequestCanceler,
        id: string,
        organization_id?: string
    ): Promise<SiteDetailsResponse> => {
        try {
            const response = await this.sitesDataService.getSiteDetails(cts, id, organization_id);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public getClients = async (
        cts: XHRRequestCanceler,
        organization_id?: string
    ): Promise<ClientsResponse> => {
        try {
            const response = await this.sitesDataService.getClients(cts, organization_id);
            this.dispatch(SitesActions.SetClients(response.data));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public createSite = async (
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        organization_id?: string
    ): Promise<SiteDetailsResponse> => {
        try {
            const response = await this.sitesDataService.createSite(
                cts,
                name,
                state,
                client,
                code,
                address,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public updateSite = async (
        id: string,
        cts: XHRRequestCanceler,
        name: string,
        state: SiteState,
        client: string,
        code: string,
        address: string,
        organization_id?: string
    ): Promise<SiteDetailsResponse> => {
        try {
            const response = await this.sitesDataService.updateSite(
                id,
                cts,
                name,
                state,
                client,
                code,
                address,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public exportXLS = async (auth: string, organization_id?: string) => {
        ExportUtils.downloadFileFromUrl(
            ExportUtils.getExportUrlWithParams({
                url: Constants.getRoot() + '/api-admin/v1/sites/export',
                auth: auth,
                organization_id: organization_id,
            })
        );
    };
}

const SitesServiceName = 'SitesService';
export { SitesService, SitesServiceName };
