import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../Constants';
import { BaseApiDataResponse } from '../Models/ApiData';
import {
    BackOfficeUsersResponse,
    CreateOrUpdateBackOfficeUserRequest,
    MeResponse,
    UserResult,
} from '../Models/Users/Users';
import { BaseDataService } from './Base/BaseDataService';

const UserEndpoints = {
    ME: '/api-admin/v1/me',
    USERS: '/api-admin/v1/backofficeusers',
    USER_CREATE: '/api-admin/v1/backofficeusers',
    USER_DETAILS: (id: string) => `/api-admin/v1/backofficeusers/${id}`,
    USER_INVITE: (id: string) => `/api-admin/v1/backofficeusers/${id}/invite`,
    USER_ACCEPT_CGU: '/api-admin/v1/backofficeusers/accept/cgu',
};

export interface IUserDataService {
    getMyInfo: (cts?: XHRRequestCanceler) => Promise<MeResponse>;
    getBackOfficeUsers: (
        page?: number,
        count?: number,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BackOfficeUsersResponse>;
    createBackOfficeUser: (
        requestBody: CreateOrUpdateBackOfficeUserRequest,
        cts?: XHRRequestCanceler
    ) => Promise<UserResult>;
    getBackOfficeUser: (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<UserResult>;

    updateBackOfficeUser: (
        id: string,
        requestBody: CreateOrUpdateBackOfficeUserRequest,
        cts?: XHRRequestCanceler
    ) => Promise<UserResult>;

    inviteUser: (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BaseApiDataResponse>;

    acceptCgu: (cts?: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

class UserDataService extends BaseDataService implements IUserDataService {
    getMyInfo = (cts?: XHRRequestCanceler): Promise<MeResponse> =>
        this.get<MeResponse>(UserEndpoints.ME, cts);

    getBackOfficeUsers = (
        page?: number,
        count?: number,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<BackOfficeUsersResponse> => {
        const params = {
            organizationId: organization_id,
            page: page || 0,
            count: count || Constants.DEFAULT_ITEM_COUNT_PER_PAGE,
        };
        return this.get<BackOfficeUsersResponse>(UserEndpoints.USERS, cts, { params });
    };

    createBackOfficeUser = (
        requestBody: CreateOrUpdateBackOfficeUserRequest,
        cts?: XHRRequestCanceler
    ): Promise<UserResult> => {
        return this.post<UserResult>(UserEndpoints.USER_CREATE, requestBody, cts);
    };

    getBackOfficeUser = (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<UserResult> => {
        const params = {
            organizationId: organization_id,
        };
        return this.get<UserResult>(UserEndpoints.USER_DETAILS(id), cts, { params });
    };

    updateBackOfficeUser = (
        id: string,
        requestBody: CreateOrUpdateBackOfficeUserRequest,
        cts?: XHRRequestCanceler
    ): Promise<UserResult> => {
        return this.put<UserResult>(UserEndpoints.USER_DETAILS(id), requestBody, cts);
    };

    inviteUser = (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<UserResult> => {
        const params = {
            organizationId: organization_id,
        };
        return this.post<UserResult>(UserEndpoints.USER_INVITE(id), {}, cts, { params });
    };

    acceptCgu = (cts?: XHRRequestCanceler): Promise<BaseApiDataResponse> => {
        return this.patch<UserResult>(UserEndpoints.USER_ACCEPT_CGU, {}, cts);
    };
}
const UserDataServiceName = 'UserDataService';
export { UserDataService, UserDataServiceName };
