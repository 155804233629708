import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import { AbilityProvider } from '../../../../Context/AbilityProvider/AbilityProvider';
import { CustomToastContainer } from '../../CustomToastContainer/CustomToastContainer';
import { DatePickerUtilsProvider } from '../../DatePickerUtilsProvider/DatePickerUtilsProvider';
import { LoadingPage } from '../../Loading/LoadingPage/LoadingPage';
import { IntlWrapper } from '../IntlWrapper/IntlWrapper';
import { LazyLoader } from '../LazyLoader/LazyLoader';
import { MountActionsGate } from '../MountActionsGate/MountActionsGate';
import { MuiThemeProvider } from '../MuiThemeProvider/MuiThemeProvider';
import { OnBoardingProvider } from './OnBoardingProvider';

export interface AppWrapperProps {
    store: any;
    persistor: any;
    content?: React.ReactNode;
    children?: React.ReactNode;

    //* Array of Promises to execute on mount
    mountActions: Array<Promise<boolean>>;
    //* Automatically setup react-intl & intl
    //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
    setupIntl: boolean;
    //* If should trigger 'BaseActions.triggerEssentialReload'
    withEssentialReload: boolean;
}

const AppWrapper = ({
    store,
    persistor,
    content,
    children,

    mountActions = [],
    setupIntl = false,
    withEssentialReload = false,
}: AppWrapperProps) => (
    <>
        <CssBaseline />
        <DatePickerUtilsProvider>
            <MuiThemeProvider>
                <Provider store={store}>
                    <PersistGate loading={<LoadingPage />} persistor={persistor}>
                        <AbilityProvider>
                            <MountActionsGate
                                //@ts-ignore
                                actions={mountActions}
                                setupIntl={setupIntl}
                                withEssentialReload={withEssentialReload}
                            >
                                <IntlWrapper>
                                    <OnBoardingProvider>
                                        <LazyLoader>
                                            <div style={{ height: '100%' }}>
                                                {children ? children : content}
                                                <CustomToastContainer />
                                            </div>
                                        </LazyLoader>
                                    </OnBoardingProvider>
                                </IntlWrapper>
                            </MountActionsGate>
                        </AbilityProvider>
                    </PersistGate>
                </Provider>
            </MuiThemeProvider>
        </DatePickerUtilsProvider>
    </>
);

export { AppWrapper };
