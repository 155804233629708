import { ErrorHandler } from '../../Libs/xhr/ErrorHandler';
import { getToastService } from '../Services/Contracts/IToastService';
import { I18nService, I18nServiceName } from '../Services/Data/I18n/I18nService';
import { getLogService, ILogService } from '../Services/RudantLogService';
import { IoC } from '../Services/ServicesContainer';

export class RudantErrorHandler extends ErrorHandler {

    private i18nService: I18nService;
    private logService: ILogService;

    constructor() {
        super();
        this.i18nService = IoC.get<I18nService>(I18nServiceName);
        this.logService = getLogService();
    }

    public handle = (error: any): boolean => {

        const { config, response } = error;
        const { noCheckRequired } = config;

        if (!noCheckRequired) {
            let message = 'An error occured with the server';
            const {
                data: { error_code = '', error_message = '' },
            } = response || { data: { error_code: '', error_message: '' } };

            message = this.i18nService.getString(`Error_${error_code}`)
                || error_code || error_message
                || this.i18nService.getString(`Error_GenericError`)
                || "Quelque chose ne s'est pas passé comme prévu, merci de réessayer";

            const toastService = getToastService();
            toastService.showError(message);
        }

        const dataError = {
            log_level: 'error',
            timestamp: new Date(),
            context: { userAgent: navigator.userAgent, location: window.location },
            error: { config, response },
        };

        this.logService.error('API ERR', dataError);

        return this.ERROR_HANDLED;
    }
}
