import { XHRRequestCanceler } from '../../../../../Libs/xhr/XHRRequestCanceler';
import { IUserDataService, UserDataServiceName } from '../../../../DataServices/UserDataService';
import { BaseApiDataResponse } from '../../../../Models/ApiData';
import {
    BackOfficeUsersResponse,
    CreateOrUpdateBackOfficeUserRequest,
    UserResult,
} from '../../../../Models/Users/Users';
import { IoC } from '../../../ServicesContainer';
import { BaseReduxService, IBaseReduxService } from '../../Api/BaseReduxService';
import { BackOfficeUsersActions } from './BackOfficeUserActions';

export interface IBackOfficeUsersService extends IBaseReduxService {
    loadUsers: (
        page: number,
        count: number,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BackOfficeUsersResponse>;
    getUser: (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<UserResult>;
    createUser: (
        data: CreateOrUpdateBackOfficeUserRequest,
        cts: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<UserResult>;
    updateUser: (
        id: string,
        data: CreateOrUpdateBackOfficeUserRequest,
        cts: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<UserResult>;
    setPage: (page: number) => void;

    inviteUser: (
        userId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ) => Promise<BaseApiDataResponse>;

    acceptCgu: (cts?: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

class BackOfficeUsersService extends BaseReduxService {
    private userDataService: IUserDataService;

    constructor() {
        super();
        this.userDataService = IoC.get<IUserDataService>(UserDataServiceName);
    }

    public loadUsers = async (
        page: number,
        count: number,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<BackOfficeUsersResponse> => {
        try {
            const response: BackOfficeUsersResponse = await this.userDataService.getBackOfficeUsers(
                page,
                count,
                cts,
                organization_id
            );

            this.dispatch(BackOfficeUsersActions.setBackofficeUsersInitialized(true));
            this.dispatch(BackOfficeUsersActions.setBackofficeUsers(response.data));

            return Promise.resolve(response);
        } catch (error) {
            this.dispatch(BackOfficeUsersActions.setBackofficeUsers([]));
            this.dispatch(BackOfficeUsersActions.setBackofficeUsersInitialized(true));
            return Promise.reject(error);
        }
    };

    public getUser = async (
        id: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<UserResult> => {
        try {
            const response: UserResult = await this.userDataService.getBackOfficeUser(
                id,
                cts,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public createUser = async (
        data: CreateOrUpdateBackOfficeUserRequest,
        cts: XHRRequestCanceler,
        organization_id?: string
    ): Promise<UserResult> => {
        try {
            data.organization_id = organization_id;
            const response: UserResult = await this.userDataService.createBackOfficeUser(data, cts);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public updateUser = async (
        id: string,
        data: CreateOrUpdateBackOfficeUserRequest,
        cts: XHRRequestCanceler,
        organization_id?: string
    ): Promise<UserResult> => {
        try {
            data.organization_id = organization_id;
            const response: UserResult = await this.userDataService.updateBackOfficeUser(
                id,
                data,
                cts
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public setPage = (page: number) => {
        this.dispatch(BackOfficeUsersActions.setBackOfficeUsersPage(page));
    };

    public inviteUser = async (
        userId: string,
        cts?: XHRRequestCanceler,
        organization_id?: string
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.userDataService.inviteUser(
                userId,
                cts,
                organization_id
            );
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public acceptCgu = async (cts?: XHRRequestCanceler): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.userDataService.acceptCgu(cts);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

const BackOfficeUsersServiceName = 'BackOfficeUsersService';
export { BackOfficeUsersService, BackOfficeUsersServiceName };
