import * as SitesActionsTypes from './typings/SitesActionsTypes';
import { SitesState } from './typings/SitesState';

const initialState: SitesState = {
    sites: [],
    page: 0,
    initialized: false,
    filters: { state: '', search: '' },
    clients: [],
};

const SitesReducer = (state = initialState, action: SitesActionsTypes.SitesActions): SitesState => {
    switch (action.type) {
        case SitesActionsTypes.SitesActionsTypes.SET_SITES_LIST:
            return { ...state, sites: action.payload.sites };
        case SitesActionsTypes.SitesActionsTypes.SET_SITES_STATE:
            return { ...state, filters: { ...state.filters, state: action.payload.state } };
        case SitesActionsTypes.SitesActionsTypes.SET_SITES_SEARCH:
            return { ...state, filters: { ...state.filters, search: action.payload.search } };
        case SitesActionsTypes.SitesActionsTypes.SET_SITES_PAGE:
            return { ...state, page: action.payload.page };
        case SitesActionsTypes.SitesActionsTypes.SET_SITES_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case SitesActionsTypes.SitesActionsTypes.SET_CLIENTS:
            return { ...state, clients: action.payload.clients };
        default:
            return state;
    }
};

export { SitesReducer };
